import React from 'react';
import BasePage from 'pages/BasePage';
import { scrollTo } from 'sf/helpers/domHelper';
import Button from 'sf/components/Button';
import { asset } from 'helpers';
import { downloadFileFromUrl } from 'sf/helpers';
import DOCUMENTS from './document_list.json';

const ITEMS_PER_PAGE = 30;

const Row = ({ item }) => {
  return (
    <tr>
      <td>{ item.continent }</td>
      { /* <td>{item.region}</td> */ }
      { /* <td>{item.sovereignty}</td> */ }
      { /* <td>{item.countryCode}</td> */ }
      <td>{ item.territory }</td>
      { /* <td>{item.stateCode}</td> */ }
      { /* <td>{item.stateName}</td> */ }
      <td>{ item.year }</td>
      <td>{ item.document }</td>
      { /* <td>{item.deprecated}</td> */ }
      <td>{ item.created }</td>
      <td>{ item.updated }</td>
    </tr>
  );
};

export default class SupportedCountries extends BasePage {
  className = 'ts-SupportedCountries';
  static title = 'Supported Countries';
  static description =
    'Global Accessibility: Opening Doors to Users in Over 200+ Countries and Territories';

  state = {
    currentPage: 0
  };

  downloadPDF = () => {
    downloadFileFromUrl(asset`ai/ts_list_of_supported_documents.xls`);
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage }, () => {
      scrollTo(0);
    });
  };

  render() {
    const { currentPage } = this.state;
    const totalItems = DOCUMENTS.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const paginatedContent = DOCUMENTS.slice(startIndex, endIndex);

    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <h1 id="title">List of Supported Documents</h1>
          <p>
            Global Accessibility: Opening Doors to Users in Over{ ' ' }
            { totalItems || '200+' } Countries and Territories
          </p>
          <Button className={ this.cn`__button` } onClick={ this.downloadPDF }>
            Download The List of Supported Documents
          </Button>
          <div className={ this.cn`__table-container` }>
            <table>
              <thead>
                <tr>
                  <th>Country Continent</th>
                  { /* <th>Country Region</th> */ }
                  { /* <th>Sovereignty</th> */ }
                  { /* <th>ICAO Country Code</th> */ }
                  <th>Country / Territory</th>
                  { /* <th>State Code</th> */ }
                  { /* <th>State Name</th> */ }
                  <th>Year</th>
                  <th>Document</th>
                  { /* <th>Deprecated</th> */ }
                  <th>Created</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                { paginatedContent.map((item, index) => (
                  <Row key={ startIndex + index } item={ item } />
                )) }
              </tbody>
            </table>
          </div>
          <div className={ this.cn`__pagination` }>
            <button
              onClick={ () => this.handlePageChange(currentPage - 1) }
              disabled={ currentPage === 0 }
              className={ this.cn`__pagination--button` }
            >
              Previous
            </button>
            <span>
              Page { currentPage + 1 } of { totalPages }
            </span>
            <button
              onClick={ () => this.handlePageChange(currentPage + 1) }
              disabled={ currentPage >= totalPages - 1 }
              className={ this.cn`__pagination--button` }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}
