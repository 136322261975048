/* global DEFAULT_PAGE_TITLE */
/* global DEFAULT_DESCRIPTION */
import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import brandingVariables from 'brandingVariables';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import is from 'next-is';

export default class HeaderTags extends BaseComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    helmetized: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    helmetized: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      description: props.description,
    };

    this.subscribe('updateHeaderTags', (headerTags) => {
      const { title, description } = headerTags;

      const updatedTitle = `${title || DEFAULT_PAGE_TITLE} | Trust Stamp`;
      const updatedDescription = description || DEFAULT_DESCRIPTION;

      this.setState({
        title: updatedTitle,
        description: updatedDescription,
      });

      if (is.browser()) {
        setTimeout(() => {
          // Update the document title
          document.title = updatedTitle;

          // Update the meta description
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', updatedDescription);
          } else {
            const newMeta = document.createElement('meta');
            newMeta.setAttribute('name', 'description');
            newMeta.setAttribute('content', updatedDescription);
            document.head.appendChild(newMeta);
          }
        });
      }
    });
  }

  getTags() {
    return [
      <title>{ this.state.title }</title>,
      <meta name="og:title" content={ this.state.title } />,
      <meta name="og:description" content={ this.state.description } />,
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />,
      <meta httpEquiv="Pragma" content="no-cache" />,
      <meta httpEquiv="Expires" content="0" />,
      /* eslint-disable max-len */
      <meta charSet="utf-8" />,
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />,
      // Add Open Graph (OG) Tags
      <meta property="og:type" content="website" />,
      <meta property="og:site_name" content="Trust Stamp" />,
      // Since when a link is shared no JS is executed on the client
      // and the title and description are set in the constructor
      <meta property="og:image" content="https://dev.truststamp.net/img/truststamp-og.png" />,

      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />,
      ENV === 'prod' ? null : <meta name="robots" content="noindex, nofollow" />,
      <meta name="google-site-verification" content="D5WmEH-pe6LCn4idGo3vqyZXgP2MFNuq0nJfu7FsSr0" />,
      <link href="https://truststamp.ai/" rel="canonical" />,
      /* browsers may shoot favicon.ico request, even if it's not here */
      <link rel="shortcut icon" type="image/x-icon" href={ asset`favicon.ico` } />,
      <link rel="apple-touch-icon" sizes="57x57" href={ asset`img/favicon/apple-icon-57x57.png` } />,
      <link rel="apple-touch-icon" sizes="60x60" href={ asset`img/favicon/apple-icon-60x60.png` } />,
      <link rel="apple-touch-icon" sizes="72x72" href={ asset`img/favicon/apple-icon-72x72.png` } />,
      <link rel="apple-touch-icon" sizes="76x76" href={ asset`/img/favicon/apple-icon-76x76.png` } />,
      <link rel="apple-touch-icon" sizes="114x114" href={ asset`img/favicon/apple-icon-114x114.png` } />,
      <link rel="apple-touch-icon" sizes="120x120" href={ asset`img/favicon/apple-icon-120x120.png` } />,
      <link rel="apple-touch-icon" sizes="144x144" href={ asset`img/favicon/apple-icon-144x144.png` } />,
      <link rel="apple-touch-icon" sizes="152x152" href={ asset`img/favicon/apple-icon-152x152.png` } />,
      <link rel="apple-touch-icon" sizes="180x180" href={ asset`img/favicon/apple-icon-180x180.png` } />,
      <link rel="icon" type="image/png" href={ asset`img/favicon/favicon-32x32.png` } sizes="32x32" />,
      <link rel="icon" type="image/png" href={ asset`img/favicon/favicon-194x194.png` } sizes="194x194" />,
      <link rel="icon" type="image/png" href={ asset`img/favicon/favicon-96x96.png` } sizes="96x96" />,
      <link rel="icon" type="image/png" href={ asset`img/favicon/android-chrome-192x192.png` } sizes="192x192" />,
      <link rel="icon" type="image/png" href={ asset`img/favicon/favicon-16x16.png` } sizes="16x16" />,
      <link rel="manifest" href={ asset`img/favicon/manifest.json` } />,
      <link rel="mask-icon" href={ asset`img/favicon/safari-pinned-tab.svg` } color="#00ad68" />,
      <meta name="msapplication-TileColor" content="#da532c" />,
      <meta name="msapplication-TileImage" content={ asset`img/favicon/mstile-144x144.png` } />,
      <meta name="msapplication-config" content={ asset`img/favicon/browserconfig.xml` } />,
      <meta name="theme-color" content="#ffffff" />,
      ..._get(brandingVariables, 'includeFonts', []).map((fontUrl) =>
        <link href={ fontUrl } rel="stylesheet" />),
      /* eslint-enable */
    ].filter((tag) => tag && tag.type);
  }

  render() {
    const tags = this.getTags();

    return this.props.helmetized ? (
      <Helmet
        style={ [{
          cssText: this.props.styleAddOn,
        }] }
      >
        { tags.map(({ type, props }) => {
          return React.createElement(
            type,
            {
              ...props,
              key: Object.values(props).join(),
            },
          );
        }) }
      </Helmet>
    ) : tags;
  }
}
