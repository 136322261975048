/* global BRAND_NAME */
import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import reduxStore from 'reduxStore';
import { brandName } from 'brandingVariables';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import HelpCenterNotification from 'components/HelpCenterNotification';
import PendingInvitationsDialogLink from 'components/PendingInvitationsDialogLink';
import Render from 'components/Render';
import SignOutModalLink from 'components/SignOutModal';
import * as UserPanelActions from 'components/UserPanel/UserPanel.actions';
import { p2pBlogURL } from 'config';
import { BRANDINGS, ROLES, ROUTES } from 'constants';
import device from 'models/device';
import help from 'models/help';
import login from 'models/login';
import user from 'models/user';
import * as ProfileActions from 'pages/Profile/Profile.actions';
import Icon from 'sf/components/Icon';
import { getWindowHeight } from 'sf/helpers/domHelper';
import { asset } from 'sf/helpers';

import ProfileDropdownItem from './ProfileDropdownItem';

export class UserPanelComponent extends BaseComponent {
  className = 'ts-UserPanel';

  static propTypes = {
    active: PropTypes.bool,
    type: PropTypes.string,
    brandingName: PropTypes.string,
    showRegisterButton: PropTypes.bool,
    actions: PropTypes.shape({
      contactUs: PropTypes.func,
      logIn: PropTypes.func,
      register: PropTypes.func
    }),
    loginPanelStructureElement: PropTypes.object
  };

  static defaultProps = {
    active: true,
  };

  state = {
    dropdownNotificationsCount: 0,
    isDropdownVisible: false,
    isSubmenuOpen: false,
    notifications: [],
    roles: [],
    smDown: true,
    showDemoButton: false
  };

  componentDidMount() {
    const notifications = {};
    this.subscribe('notification', (notificationName, notificationsCount) => {
      notifications[notificationName] = parseInt(notificationsCount, 10);

      this.setState({
        dropdownNotificationsCount: Object.values(notifications).reduce(
          (result, value) => {
            return result + (value || 0);
          },
          0
        )
      });
    });
  }

  componentDidMount() {
    const additionalDropdownResolution =
      this.props.brandingName === BRANDINGS.AI ? 'mdDown' : '';
    this.syncStateWithModel(device, ['smDown', additionalDropdownResolution]);
    this.syncStateWithModel(user, [
      'legal_name',
      'absolute_small_photo_url',
      'isSignedIn',
      'roles',
      'score'
    ]);
    this.syncStateWithModel(help, ['notifications', 'showDemoButton']);
  }

  componentDidUpdate() {
    login.set({
      businessLogIn: brandName === BRANDINGS.REALTOR
    });
  }

  getBreakPointDown = () =>
    BRAND_NAME === 'ai' ? this.state.mdDown : this.state.smDown;

  handleProfileClick = (event) => {
    event.preventDefault();
    if (this.state.isDropdownVisible && this.getBreakPointDown()) {
      this.handleLinkClick(event, '/Profile.html');
    } else {
      this.handleDropdownTriggerClick();
    }
  };

  handleLinkClick = async (event, href, action) => {
    if (event) event.preventDefault();
    if (action) action();
    await this.handleCloseDropdown();
    if (is.string.isLink(href)) {
      window.open(href, '_blank');
    } else {
      this.navigate(href, 'replace');
    }
  };

  handleSubmenuOpenClick = (event, name) => {
    if (event) event.preventDefault();
    if (!this.getBreakPointDown()) return;
    if (this.state.isSubmenuOpen && this.state.activeMenu === name) {
      this.handleCloseSubmenu(name);
    } else {
      this.handleOpenSubmenu(name);
    }
  };

  handleSubmenuOpenMouseEnter = (event, name) => {
    if (event) event.preventDefault();
    if (this.getBreakPointDown()) return;
    this.handleOpenSubmenu(name);
  };

  handleSubmenuCloseMouseLeave = (event, name) => {
    if (event) event.preventDefault();
    if (this.getBreakPointDown()) return;
    this.handleCloseSubmenu(name);
  };

  handleOpenSubmenu = (name) => {
    this.setState(
      {
        isSubmenuOpen: true,
        activeMenu: name
      },
      () => {
        if (this.getBreakPointDown()) {
          const dropdownContainer = this.dropdownNode.parentNode;
          dropdownContainer.style.height = 'auto';
        }
      }
    );
  };

  handleCloseSubmenu = (name) => {
    this.setState({
      isSubmenuOpen: false,
      activeMenu: name
    });
  };

  handleDropdownTriggerClick = (event, name) => {
    if (event) event.preventDefault();
    if (this.state.isDropdownVisible) {
      this.handleCloseDropdown(name);
    } else {
      this.handleOpenDropdown(name);
    }
  };

  handleClickCatcherClick = (name) => {
    this.handleCloseDropdown(name);
  };

  handleOpenDropdown = (name) => {
    this.setState(
      {
        isDropdownVisible: true,
        activeMenu: name
      },
      () => {
        const dropdownContainer = this.dropdownNode.parentNode;

        if (this.getBreakPointDown()) {
          const { top } = dropdownContainer.getBoundingClientRect();
          const height = Math.min(
            dropdownContainer.scrollHeight,
            getWindowHeight() - top
          );
          dropdownContainer.style.height = `${height}px`;
          document.body.classList.add('scroll--disabled');
        } else {
          dropdownContainer.style.height = '';
          document.body.classList.remove('scroll--disabled');
        }
      }
    );
  };

  handleCloseDropdown = (name, callback = noop) =>
    new Promise((resolve) => {
      this.setState(
        {
          isDropdownVisible: false,
          activeMenu: name
        },
        () => {
          if (this.getBreakPointDown()) {
            document.body.classList.remove('scroll--disabled');
          }
          callback();
          resolve();
        }
      );
    });

  openHelpCenter = () => {
    help.toggle();
  };

  renderItemsForContainer(containerName) {
    const { brandingName, type } = this.props;
    const { isSignedIn, smDown, mdDown } = this.state;
    let registerButtonApperance = null;
    if (!this.state.isSignedIn) {
      registerButtonApperance = this.getBreakPointDown() ? 'link' : 'button';
    }
    const photo = this.state.absolute_small_photo_url;
    const isScoreUser =
      this.state.roles && this.state.roles.includes(ROLES.SCORE_USER);
    const panelStructure = [
      {
        container: 'navbar',
        type: 'component',
        name: 'notification',
        component: (
          <Button
            theme="link-unstyled"
            disabled={ !this.state.notifications.length }
            onClick={ this.openHelpCenter }
          >
            <HelpCenterNotification iconSize={ 22 } label={ null } />
          </Button>
        )
      }
    ];
    if (brandingName === BRANDINGS.CORPORATE_LANDING) {
      panelStructure.push(
        {
          container: smDown ? 'dropdown' : 'navbar',
          href: '/',
          type: 'link',
          value: 'Home',
          name: 'home'
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          href: '/TeamMembers.html',
          type: 'link',
          value: 'Team',
          name: 'team-members'
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          href: '/PressAndMedia.html',
          type: 'link',
          value: 'Press and Media',
          name: 'press-and-media'
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          href: '/ContactUs.html',
          type: BRAND_NAME === BRANDINGS.CORPORATE_LANDING ? 'link' : null,
          value: 'Request a Call',
          name: 'contact-us'
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          href: '/ContactUs.html',
          type: BRAND_NAME !== BRANDINGS.CORPORATE_LANDING ? 'link' : null,
          value: 'Contact Us',
          name: 'contact-us'
        }
      );
    } else if (brandingName === BRANDINGS.AI) {
      panelStructure.push(
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: '/',
          type: 'link',
          value: 'Home',
          name: 'home'
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: '',
          type: 'dropdown',
          value: 'Products',
          name: 'products',
          description:
            'Empowering Your Business with Privacy-First, AI-Driven Identity Verification and Authentication.',
          flex: true,
          summaryLink: {
            container: mdDown ? 'dropdown' : 'navbar',
            href: ROUTES.IDENTITY_TOOLS,
            type: 'link',
            value: 'See More',
            name: 'See More'
          },
          submenu: [
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.VERIFY,
              type: 'link',
              value: 'KYC Onboarding',
              description: 'Identity Verification',
              name: 'KYC Onboarding'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.IDENTIFY,
              type: 'link',
              value: 'Document Verification',
              description: 'Identity Verification',
              name: 'identity-verification'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.BIOMETRIC_MFA,
              type: 'link',
              value: 'Facial Matching',
              description: 'Biometric Authentication',
              name: 'Facial Matching'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.CAPTURE,
              type: 'link',
              value: 'Liveness Detection',
              description: 'Biometric Authentication',
              name: 'Liveness Detection'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.DETECT,
              type: 'link',
              value: 'Biometric Deduplication',
              description: 'Biometric Authentication',
              name: 'Biometric Deduplication'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.AGE_ESTIMATION,
              type: 'link',
              value: 'Age Estimation',
              description: 'Facial Age Assurance',
              name: 'Age Estimation'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.AGE_VERIFICATION,
              type: 'link',
              value: 'Age Verification',
              description: 'Facial Age Assurance',
              name: 'Age Verification'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.STABLE_IT2,
              type: 'link',
              value: 'Stable IT2',
              description: 'Privacy Enhancing Biometrics',
              name: 'Stable IT2'
            }
          ]
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: '',
          type: 'dropdown',
          value: 'Solutions',
          name: 'Solutions',
          description:
            'Explore How Our Privacy-First Identity Technologies Address Diverse Industry Needs and Challenges.',
          flex: true,
          summaryLink: {
            container: mdDown ? 'dropdown' : 'navbar',
            href: ROUTES.INDUSTRIES,
            type: 'link',
            value: 'Industries',
            name: 'Industries'
          },
          submenu: [
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.PROTECT,
              type: 'link',
              value: 'Privacy and Data Protection',
              description: 'Biometric Security',
              name: 'Privacy and Data Protection'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.DETECT,
              type: 'link',
              value: 'Duplicate Identity Detection',
              description: 'Biometric Authentication',
              name: 'Duplicate Identity Detection'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.GEOLOCATION,
              type: 'link',
              value: 'Geolocation',
              description: 'Geolocation',
              name: 'Geolocation'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.STABLE_IT2,
              type: 'link',
              value: 'Stable IT2',
              description: 'Crypto Security and Compliance',
              name: 'Stable IT2'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.TRUSTED_MESSAGE,
              type: 'link',
              value: 'Message Encryption',
              description: 'Trusted Message',
              name: 'Message Encryption'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.WIRE_FRAUD_PREVENTION,
              type: 'link',
              value: 'Wire Fraud Prevention',
              description: 'Fraud Prevention',
              name: 'Wire Fraud Prevention'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.TRAVEL_HOSPITALITY,
              type: 'link',
              value: 'Travel & Hospitality',
              description: 'Biometric verification for Travel & Hospitality',
              name: 'Travel & Hospitality'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.DEDUPLICATION_CHECKS,
              type: 'link',
              value: 'Deduplication & Greylist Checks',
              description: 'Fraud Prevention',
              name: 'Deduplication & Greylist Checks'
            }
          ]
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: ROUTES.PARTNERS,
          type: 'link',
          value: 'Partners',
          name: 'partners'
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: '',
          type: 'dropdown',
          value: 'Company',
          name: 'company',
          description:
            'Discover Our Mission, Meet Our Team, Read Our Latest Blogs, and Learn Why Investors Trust Us.',
          submenu: [
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.INVESTORS,
              type: 'link',
              value: 'Investors',
              name: 'investors'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.BLOGS,
              type: 'link',
              value: 'Blogs',
              name: 'blogs'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.B_RESOURCES,
              type: 'link',
              value: 'Resources',
              name: 'resources'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.MISSION,
              type: 'link',
              value: 'Mission',
              name: 'mission'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.TEAM,
              type: 'link',
              value: 'Team',
              name: 'team'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.CAREERS,
              type: 'link',
              value: 'Careers',
              name: 'careers'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.DISCLOSURE,
              type: 'link',
              value: 'Disclosures',
              name: 'Disclosures'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.NEWS,
              type: 'link',
              value: 'News',
              name: 'news'
            }
          ]
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: '',
          type: 'dropdown',
          value: 'Gov.S',
          name: 'Gov.S',
          description:
            'Secure, Efficient, and Privacy-Focused Identity Solutions Tailored for Government Needs.',
          submenu: [
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.PRIVTECH,
              type: 'link',
              value: 'Privtech',
              name: 'Privtech - Privacy-First Solutions'
            },
            {
              container: mdDown ? 'dropdown' : 'navbar',
              href: ROUTES.GEOLOCATION,
              type: 'link',
              value: 'Alternatives to Detention',
              name: 'Alternatives to Detention'
            }
          ]
        },
        {
          container: mdDown ? 'dropdown' : 'navbar',
          href: this.state.showDemoButton
            ? ROUTES.SCHEDULE_DEMO
            : ROUTES.CONTACT_US,
          type: BRAND_NAME !== BRANDINGS.CORPORATE_LANDING ? 'link' : null,
          value: this.state.showDemoButton ? 'Book Demo' : 'Contact Us',
          name: 'contact'
        }
        // {
        //   container: mdDown ? 'dropdown' : 'navbar',
        //   href: ROUTES.INVESTORS,
        //   type: BRAND_NAME !== BRANDINGS.CORPORATE_LANDING ?
        //     'link' :
        //     null,
        //   value: 'Invest',
        //   name: 'invest-now',
        // },
      );
    } else {
      /* eslint-disable max-len */
      panelStructure.push(
        {
          container: 'navbar',
          href: this.FAQHref,
          type: smDown || !this.FAQHref ? null : 'link',
          value: 'FAQ',
          name: 'faq',
          action: this.props.actions.faq
        },
        {
          container: 'navbar',
          href: ROUTES.CONTACT_US,
          type: smDown ? null : 'link',
          value:
            brandingName === BRANDINGS.REALTOR && !isSignedIn
              ? 'Book a demo'
              : 'Contact Us',
          name: 'contact-us',
          action: this.props.actions.contactUs
        },
        {
          container: 'navbar',
          href: p2pBlogURL,
          type: smDown ? null : 'link',
          value: 'Press',
          name: 'blog'
        },
        this.props.loginPanelStructureElement || {
          container: smDown ? 'dropdown' : 'navbar',
          href:
            BRAND_NAME === BRANDINGS.REALTOR
              ? '/business/LogInCredentials.html'
              : '/LogIn.html',
          type: isSignedIn ? null : 'link',
          value: 'Log In',
          name: 'log-in',
          action: this.props.actions.logIn
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          href:
            BRAND_NAME === BRANDINGS.REALTOR
              ? '/business/SignUpBasicInfo.html'
              : `/SignUp.html?mode=${brandingName}`,
          type: type !== BRANDINGS.REALTOR ? registerButtonApperance : null,
          value: 'Register',
          name: 'register'
        },
        {
          action: () => {
            // (,╯︵╰,)
            // I'm so sorry for this nasty HACK: with a timeout, but it's the only way I know right now
            // to dispatch this initial action AFTER and NOT BEFORE persistor/REHYDRATE
            //  ¯\_(ツ)_/¯
            // TODO: resolve this issue; make saveMPHDiscountCode and helpcenter actions great again
            if (brandName === BRANDINGS.REALTOR) {
              setTimeout(() =>
                reduxStore.dispatch(
                  ProfileActions.saveMPHDiscountCode('realtor_100')
                ));
            }
          },
          brandingName,
          className: this.cn`__button--big`,
          container: smDown ? 'dropdown' : 'navbar',
          href: '/business/SignUpBasicInfo.html',
          type:
            BRAND_NAME === BRANDINGS.REALTOR && this.props.showRegisterButton
              ? registerButtonApperance
              : null,
          value: 'Register',
          name: 'register'
        },
        {
          container: smDown ? 'dropdown' : 'navbar',
          type: isSignedIn ? 'component' : null,
          value: 'profile',
          name: 'profile',
          component: this.state.legal_name && (
            <ProfileDropdownItem
              legalName={ this.state.legal_name }
              notificationsCount={ this.state.dropdownNotificationsCount }
              photo={ photo }
              onClick={ this.handleProfileClick }
            />
          )
        },
        {
          container: 'dropdown',
          href: '/Profile.html',
          type: isSignedIn && isScoreUser ? 'link' : null,
          value: 'Your Profile',
          name: 'your-profile'
        },
        {
          container: 'dropdown',
          href: '/Wallet.html',
          type: isSignedIn && brandName !== BRANDINGS.REALTOR ? 'link' : null,
          value: 'Your Wallet',
          name: 'your-wallet'
        },
        {
          container: 'dropdown',
          href: '/AskSomeoneToVerify.html',
          type: isSignedIn ? 'link' : null,
          value:
            brandName === BRANDINGS.REALTOR
              ? 'Invite & Evaluate'
              : 'Ask to verify',
          name: 'ask-someone-to-verify'
        },
        {
          container: 'dropdown',
          type: isSignedIn && isScoreUser ? 'component' : null,
          value: 'Pending Invitations',
          name: 'pending-invitations',
          component: (
            <PendingInvitationsDialogLink
              active={ this.props.active }
              onClick={ this.handleCloseDropdown }
            >
              Invitations
            </PendingInvitationsDialogLink>
          )
        },
        {
          container: 'dropdown',
          href: '/Settings.html',
          type:
            isSignedIn &&
            user.hasRole([ROLES.BUSINESS_USER, ROLES.ORGANIZATION_AGENT])
              ? 'link'
              : null,
          value: 'Settings',
          name: 'settings'
        },
        {
          action: this.props.actions.contactUs,
          container: 'dropdown',
          href: ROUTES.CONTACT_US,
          type: smDown ? 'link' : null,
          value: 'Contact Us',
          name: 'contact-us'
        },
        {
          container: 'dropdown',
          type: isSignedIn ? 'component' : null,
          value: 'Log out',
          name: 'log-out',
          component: (
            <SignOutModalLink linkClickCallback={ this.handleCloseDropdown }>
              <Icon set="io" type="log-out" />
              Log out
            </SignOutModalLink>
          )
        }
        /* eslint-enable max-len */
      );
    }

    return panelStructure
      .filter(({ container }) => container === containerName)
      .map((item, index) => {
        return this.renderItem(item, index);
      });
  }

  // render menu dropdown submenu listing
  renderSubMenu = (submenu, flex) => {
    // create separate arrays of logos & links
    const links = submenu.filter((item) => item.type === 'link');
    const logos = submenu.filter((item) => item.type === 'logo');

    if (logos.length) {
      return (
        <div className={ this.cn`__submenu-wrapper` }>
          { /* links */ }
          <div className={ this.cn`__submenu-items` }>
            { links.map((item) => (
              <div key={ item.name } className={ this.cn`__submenu-item` }>
                <a
                  href={ item.href }
                  className={ this.cn`__submenu-link` }
                  onClick={ (event) => {
                    this.handleLinkClick(event, item.href, item.action);
                    this.handleCloseSubmenu(event, name);
                  } }
                >
                  { item.value }
                </a>
              </div>
            )) }
          </div>

          { /* logos */ }
          <div className={ this.cn`__submenu-logos` }>
            <h5>Our Initiatives</h5>
            { logos.map((item) => (
              <a
                href={ item.href }
                target="_blank"
                className={ this.cn`__logo __logo-${item.name}` }
                rel="noreferrer"
              >
                <img src={ asset`/ai/${item.slug}.svg` } alt={ item.value } />
              </a>
            )) }
          </div>
        </div>
      );
    }
    return (
      // links only
      <div
        className={ this.cn({
          '__submenu-items': true,
          '__submenu-items--two-columns': submenu.length <= 2,
          '__submenu-items--flex': !flex
        }) }
      >
        { submenu.map((item) => (
          <a
            href={ item.href }
            onClick={ (event) => {
              this.handleLinkClick(event, item.href, item.action);
              this.handleCloseSubmenu(event, name);
            } }
            key={ item.name }
            className={ this.cn`__submenu-item` }
          >
            { item.value }
            { item.description && item.description.trim() !== '' && (
              <p>{ item.description }</p>
            ) }
          </a>
        )) }
      </div>
    );
  };

  renderItem(
    {
      brandingName,
      className,
      container,
      href = null,
      name,
      type,
      value,
      component,
      action,
      submenu,
      description,
      flex,
      summaryLink
    },
    index
  ) {
    if (!type) return null;
    let itemMarkup;
    switch (type) {
      case 'component':
        itemMarkup = component;
        break;
      case 'button':
        itemMarkup = (
          <Button
            className={ className }
            onClick={ (event) => this.handleLinkClick(event, href, action) }
            theme={
              brandingName === BRANDINGS.REALTOR && name === 'register'
                ? 'ternary'
                : 'success'
            }
          >
            { value }
          </Button>
        );
        break;
      case 'link':
        itemMarkup = (
          <a
            href={ href }
            onClick={ (event) => this.handleLinkClick(event, href, action) }
          >
            { value }
          </a>
        );
        break;
      case 'dropdown':
        itemMarkup = (
          <div
            className={ this.cn`__submenu` }
            onMouseLeave={ (event) =>
              this.handleSubmenuCloseMouseLeave(event, name)
            }
          >
            <a
              href={ href }
              onClick={ (event) => this.handleSubmenuOpenClick(event, name) }
              onMouseEnter={ (event) =>
                this.handleSubmenuOpenMouseEnter(event, name)
              }
              className={ this.cn`__submenu-trigger` }
            >
              { value }
              <Icon
                /* es=lint-disable max-len */
                className={ this.cn`__submenu-icon
                ${
                  this.state.isSubmenuOpen && this.state.activeMenu === name
                    ? '__submenu-icon--active'
                    : ''
                }` }
                set="fa"
                size={ 12 }
                type="chevron-down"
              />
            </a>
            <div
              /* eslint-disable max-len */
              className={ this.cn`__submenu-container
              ${
                this.state.isSubmenuOpen && this.state.activeMenu === name
                  ? '__submenu-container--open'
                  : ''
              }` }
            >
              { this.renderSubMenu(submenu, flex) }
              <div className={ this.cn`__submenu-summary` }>
                <h6>{ name }</h6>
                <p>{ description } </p>
                <div>
                  { summaryLink && (
                    <a
                      href={ summaryLink.href }
                      onClick={ (event) => handleLinkClick(event, summaryLink.href, summaryLink.action) }
                    >
                      { summaryLink.value } <Icon set="fa" type="arrow-right" />
                    </a>
                  ) }
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        itemMarkup = null;
    }

    return (
      <div
        className={ this.cn`__${container}-item __${container}-item--${name}` }
        key={ `item-${index}-${value}` }
      >
        { itemMarkup }
      </div>
    );
  }

  render() {
    const navbarItems = this.renderItemsForContainer('navbar');
    const dropdownItems = this.renderItemsForContainer('dropdown');
    const { isDropdownVisible, isSignedIn, mdDown } = this.state;
    const { brandingName, visible, fixed } = this.props;
    const closeIcon = visible && fixed ? 'close-thin--dark' : 'close-thin';
    const navicon = visible && fixed ? 'navicon-us--dark' : 'navicon-us';
    const getBreadcrumbIcon = () => {
      if (
        BRAND_NAME === BRANDINGS.CORPORATE_LANDING ||
        brandingName === BRANDINGS.REALTOR
      ) {
        if (isDropdownVisible) {
          return <Icon key={ closeIcon } set="ts" size={ 24 } type={ closeIcon } />;
        }
        return <Icon key={ navicon } set="ts" size={ 24 } type={ navicon } />;
      }

      if (isDropdownVisible) {
        return <Icon key="close-round" set="io" size={ 18 } type="close-round" />;
      }
      return <Icon key="navicon" set="io" size={ 26 } type="navicon" />;
    };

    const hamburgerBreakPoint = mdDown ? 'hamburger-md' : 'hamburger-sm';
    return this.props.active ? (
      <div className={ `${this.rootcn()} ${this.cn`--${hamburgerBreakPoint}`}` }>
        <Render when={ this.getBreakPointDown() }>
          <a
            className={ this.cn({
              '__dropdown-trigger': true,
              '__dropdown-trigger--dropdown-visible': isDropdownVisible
            }) }
            href="#"
            onClick={ (name) => this.handleDropdownTriggerClick(name) }
          >
            { getBreadcrumbIcon() }
          </a>
        </Render>
        <div className={ this.cn`__navbar` }>{ navbarItems }</div>
        <Render when={ this.getBreakPointDown() || isSignedIn }>
          { isDropdownVisible && (
            <div
              className={ this.cn`__dropdown-click-catcher` }
              role="presentation"
              onClick={ (name) => this.handleClickCatcherClick(name) }
            />
          ) }
          <div
            className={ this.cn({
              '__dropdown': true,
              '__dropdown--visible': isDropdownVisible
            }) }
          >
            <div ref={ this.createRef('dropdownNode') }>{ dropdownItems }</div>
          </div>
        </Render>
      </div>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...UserPanelActions
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(UserPanelComponent);
